import React, {useState, useContext} from 'react';
import {
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import ListItemLink from 'components/ListItemLink';
import Logo from 'assets/ftkt_logo.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {privateRoutes} from 'router';
import {AuthContext} from 'context/AuthContext';
import {NavLink, useHistory} from 'react-router-dom';
import ROLES from 'constants/role.contstants';
import {ROLE_TYPE} from 'App';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: '8px 24px',
		background: '#FFFFFF',
		boxShadow: '0 2px 2px -2px grey',
		marginBottom: 1,
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-between'
		}
	},
	logo: {
		width: 90,
		marginLeft: 25,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0
		}
	},
	navBar: {
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			minWidth: theme.breakpoints.values.xs
		}
	},
	menuButton: {
		color: theme.palette.primary.main,
		width: 'fit-content',
		alignSelf: 'center'
	},
	menuItem: {
		paddingLeft: 28,
		paddingRight: 28,
		'& .MuiTypography-root': {
			fontWeight: 600,
			fontSize: 14
		},
		'&.active': {
			color: theme.palette.secondary.main
		},
		'&.active .MuiListItemIcon-root': {
			color: theme.palette.secondary.main
		}
	},
	dropdownContent: {
		display: 'none',
		position: 'absolute',
		left: 0,
		top: 40,
		backgroundColor: '#f9f9f9',
		minWidth: '160px',
		boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
		zIndex: 10
	},
	dropdown: {
		position: 'relative',
		display: 'flex',
		textAlign: 'left',
		'&:hover .dropdownContent': {
			display: 'block'
		},
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)'
		}
	},
	balance: {
		paddingLeft: 16,
		paddingRight: 28,
		'& .MuiTypography-root': {
			fontWeight: 600,
			fontSize: 14
		},
		'& .active': {
			color: theme.palette.secondary.main
		},
		'& .active .MuiListItemIcon-root': {
			color: theme.palette.secondary.main
		}
	},
	currencyBtn: {
		'& .MuiButton-label': {
			justifyContent: 'flex-start',
			paddingLeft: 5
		}
	},
	navLink: {
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'block'
		}
	}
}));

const NavBar = () => {
	const classes = useStyles();
	const theme = useTheme();
	const media = useMediaQuery(theme.breakpoints.down('xs'));
	const {dispatch: authDispatch} = useContext(AuthContext);
	const history = useHistory();

	const filteredRoutes = privateRoutes.filter(el => el.navBar && el.role.includes(ROLE_TYPE));
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}

		setIsDrawerOpen(state);
	};

	const handleLogout = () => {
		authDispatch({
			type: 'LOGOUT'
		});
	};

	const MenuList = () => (
		<List className={classes.navBar}>
			{filteredRoutes.map((el: any) => {
				let listName = el.name;
				return !el.subMenu ? (
					<ListItemLink
						onClick={toggleDrawer(false)}
						key={el.path}
						to={el.path.indexOf('/:') !== -1 ? el.path.substr(0, el.path.indexOf('/:')) : el.path}
						primary={listName}
						icon={el.icon}
						disable={el.disable}
						disableForRoles={el?.disableForRoles}
					/>
				) : (
					<div key={el.name} className={classes.dropdown}>
						<ListItemLink
							onClick={toggleDrawer(false)}
							primary={el.name}
							icon={el.icon}
							disable={el.disable}
							to={el.path}
							disableForRoles={el?.disableForRoles}
						/>

						<div
							className={`${classes.dropdownContent} dropdownContent`}
							style={{
								left: el.name === 'Reports' && !media ? 'unset' : 0,
								right: el.name !== 'Reports' && !media ? 'unset' : 0
							}}>
							{el.subMenu
								.filter((el: any) => el.role.includes(ROLE_TYPE))
								.map((item: any) => {
									return (
										<ListItemLink
											onClick={toggleDrawer(false)}
											key={item.path}
											to={item.path}
											primary={item.name}
											icon={item.icon}
											disable={item.disable}
											disableForRoles={item?.disableForRoles}
										/>
									);
								})}
						</div>
					</div>
				);
			})}
			{useMediaQuery(theme.breakpoints.down('sm')) ? (
				<>
					<Divider />
					{!(ROLE_TYPE === ROLES.AGENT || ROLE_TYPE === ROLES.USER_REPORTS) && (
						<ListItemLink to="/settings" primary="Settings" onClick={toggleDrawer(false)} />
					)}
					<ListItemLink to="/profile" primary="Profile" onClick={toggleDrawer(false)} />

					<ListItem
						button
						onClick={() => {
							handleLogout();
							history.push('/');
						}}>
						<ListItemText primary="Log out" />
					</ListItem>
				</>
			) : null}
		</List>
	);

	return (
		<div className={classes.container}>
			<NavLink to="/" className={classes.navLink}>
				<img src={Logo} alt={'Logo'} className={classes.logo} />
			</NavLink>

			{useMediaQuery(theme.breakpoints.up('lg')) ? (
				<MenuList />
			) : (
				<>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer(true)}
						edge="end"
						className={classes.menuButton}
						size="medium">
						<MenuIcon />
					</IconButton>
					<Drawer
						anchor="right"
						variant="temporary"
						open={isDrawerOpen}
						onClose={toggleDrawer(false)}>
						<IconButton
							color="inherit"
							aria-label="close drawer"
							onClick={toggleDrawer(false)}
							edge="start"
							className={classes.menuButton}
							size="medium">
							<CloseIcon style={{color: 'black'}} />
						</IconButton>
						<MenuList />
					</Drawer>
				</>
			)}
		</div>
	);
};

export default NavBar;
